import Api from './Api';

export const login = (credentials) =>
  Api.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/accounts/login`,
    credentials,
  );
  
export const refreshSession = (data) =>
  Api.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/accounts/refresh-session`,
    data,
  );
