import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export default function MetricsRangeSelectorComponent({
  metricsRange,
  setMetricsRange,
}) {
  const handleChange = (event) => {
    setMetricsRange(event.target.value);
  };

  const options = [
    {
      name: 'Week',
      value: 'week',
    },
    {
      name: 'Month',
      value: 'month',
    },
    {
      name: '2 Months',
      value: 'twoMonths',
    },
    {
      name: '3 Months',
      value: 'threeMonths',
    },
    {
      name: '6 Months',
      value: 'sixMonths',
    },
    {
      name: 'Year',
      value: 'year',
    },
    {
      name: '2 Years',
      value: 'twoYears',
    },
    {
      name: '3 Years',
      value: 'threeYears',
    },
    {
      name: '5 Years',
      value: 'fiveYears',
    },
  ];

  return (
    <Box width={200}>
      <FormControl fullWidth>
        <InputLabel id="metrics-range-select-label">Period</InputLabel>
        <Select
          labelId="metrics-range-select-label"
          id="metrics-range-select"
          value={metricsRange}
          label="Period"
          onChange={handleChange}
        >
          {options.map((o) => (
            <MenuItem key={o.name} value={o.value}>
              {o.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
