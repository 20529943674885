export const COLORS = [
  '#fec5bb',
  '#fcd5ce',
  '#fae1dd',
  '#e8e8e4',
  '#d8e2dc',
  '#ece4db',
  '#ffe5d9',
  '#ffd7ba',
  '#fec89a',
  '#eae4e9',
  '#fff1e6',
  '#fde2e4',
  '#fad2e1',
  '#e2ece9',
  '#bee1e6',
  '#cddafd',
];