import { Savings } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { get } from 'lodash';
import React from 'react';
export default function EditCashDialog({
  editCash,
  editCashDialogOpen,
  setEditCashDialogOpen,
  errMessage,
  setErrMessage,
  setSnackOpen,
}) {
  const initialEditCashFormData = Object.freeze({ value: 0 });
  const [editCashFormData, setEditCashFormData] = React.useState(
    initialEditCashFormData,
  );

  const handleEditCash = (event) => {
    event.preventDefault();

    setEditCashFormData({
      ...editCashFormData,
      [event.target.name]: Number(event.target.value),
    });
  };

  const handleEditCashSubmit = async (event) => {
    event.preventDefault();

    try {
      const { value } = editCashFormData;
      await editCash({ value });
      setEditCashDialogOpen(false);
    } catch (err) {
      setErrMessage(get(err, 'response.data.message', errMessage));
      setSnackOpen(true);
    }
  };

  return (
    <Dialog
      open={editCashDialogOpen}
      onClose={() => setEditCashDialogOpen(false)}
    >
      <DialogTitle>Edit cash</DialogTitle>
      <DialogContent>
        <form onSubmit={handleEditCashSubmit}>
          <TextField
            name="value"
            style={{ margin: 10 }}
            label="Value"
            onChange={handleEditCash}
          />
          <Divider />
          <Box style={{ margin: 10 }}>
            <Button variant="contained" endIcon={<Savings />} type="submit">
              Edit cash
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
