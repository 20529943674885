import { AddTask, CardTravel } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Collapse } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function LeftMenu({ portfolios, setAddPortfolioDialogOpen }) {
  const [open, setOpen] = useState(!isMobile);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [portfoliosOpen, setPortfoliosOpen] = useState(true);

  const handlePortfoliosOpen = () => {
    setPortfoliosOpen(!portfoliosOpen);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleSelectPortfolio = (uuid) => {
    setSelectedPortfolio(uuid);
  };

  const getListItemStyle = (uuid) => {
    if (uuid === selectedPortfolio) {
      return {
        backgroundColor: 'lightgrey',
      };
    }
    return {};
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItemButton component={Link} to="/companies">
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Companies" />
        </ListItemButton>
        <ListItemButton component={Link} onClick={handlePortfoliosOpen}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Portfolios" />
        </ListItemButton>
        <Collapse in={portfoliosOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {portfolios.map((portfolio) => {
              return (
                <ListItemButton
                  style={getListItemStyle(portfolio.uuid)}
                  key={portfolio.uuid}
                  sx={{ pl: 3 }}
                  component={Link}
                  onClick={() => handleSelectPortfolio(portfolio.uuid)}
                  to={`/portfolios/${portfolio.uuid}`}
                >
                  <ListItemIcon>
                    <CardTravel />
                  </ListItemIcon>
                  <ListItemText primary={portfolio.name} />
                </ListItemButton>
              );
            })}
            <ListItemButton
              key="addPortfolio"
              sx={{ pl: 3 }}
              component={Link}
              onClick={() => setAddPortfolioDialogOpen(true)}
            >
              <ListItemIcon>
                <AddTask />
              </ListItemIcon>
              <ListItemText primary={'New'} />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
}
