import React from "react";
import { Box } from "@mui/material";
import PortfolioComponent from "../components/portfolios/PortfolioComponent";

export default function Portfolio() {

  return (
    <Box>
      <PortfolioComponent />
    </Box>
  )
}
