import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty, round, sample, sortBy } from 'lodash';
import { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  Treemap,
  XAxis,
  YAxis
} from 'recharts';
import MetricsRangeSelectorComponent from './MetricsRangeSelectorComponent';
import { COLORS } from './resources/colors';

class CustomizedContent extends PureComponent {
  truncate(input) {
    return input.length > 15 ? `${input.substring(0, 15)}...` : input;
  }

  render() {
    const { x, y, width, height, companyName } = this.props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: sample(COLORS),
            stroke: '#fff',
            strokeWidth: 2,
            strokeOpacity: 1,
          }}
        />
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={12}
        >
          {this.truncate(companyName ?? '')}
        </text>
      </g>
    );
  }
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-60)"
          fontSize={12}
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export default function PortfolioAnalyticsComponent(props) {
  const renderAverageBalancesLineChart = () => {
    const data = sortBy(props?.portfolioMetrics?.averageBalances, [
      'timestamp',
    ]).map((averageBalance) => ({
      formattedTs: format(new Date(averageBalance.timestamp), 'dd/MM/yy'),
      average: Math.round(averageBalance.average * 100) / 100,
      contributions: averageBalance.contributions,
    }));

    return (
      <LineChart
        data={data}
        height={isMobile ? 536 : 400}
        width={isMobile ? 734 : 1100}
      >
        <Line
          type="monotone"
          dataKey="average"
          name="Value (EUR)"
          stroke="#FFBB28"
        />
        <Line
          type="monotone"
          dataKey="contributions"
          name="Accumulated contributions"
          stroke="#00C49F"
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <YAxis
          domain={[
            (dataMin) => Math.round(dataMin),
            (dataMax) => Math.round(dataMax),
          ]}
        />
        <XAxis
          dataKey="formattedTs"
          height={20}
          tick={<CustomizedAxisTick />}
        />
        {props?.contributions.items
          .map((i) => format(new Date(i.timestamp), 'dd/MM/yy'))
          .map((i, idx) => (
            <ReferenceLine key={idx} x={i} stroke="blue" />
          ))}
        <Tooltip />
        <Legend />
      </LineChart>
    );
  };

  const renderReturnsLineChart = () => {
    const data = sortBy(props?.portfolioMetrics?.returns, [
      'timestamp',
    ]).map((dataPoint) => ({
      formattedTs: format(new Date(dataPoint.timestamp), 'dd/MM/yy'),
      portfolio: round(Number(dataPoint.value), 2),
      sp: round(Number(dataPoint['S&P 500']), 2),
      nasdaq: round(Number(dataPoint['NASDAQ Composite']), 2),
    }));

    if (!isEmpty(data)) {
      return (
        <LineChart
          data={data}
          height={isMobile ? 268 : 400}
          width={isMobile ? 734 : 1100}
        >
          <ReferenceLine y={0} stroke="green" strokeDasharray="3 3" />
          <Line
            type="monotone"
            dataKey="portfolio"
            stroke="#FFBB28"
            name="Portfolio"
            legendType="line"
          />
          <Line
            type="monotone"
            dataKey="nasdaq"
            stroke="#00C49F"
            name="NASDAQ"
            legendType="line"
          />
          <Line
            type="monotone"
            dataKey="sp"
            stroke="#0088FE"
            name="S&P 500"
            legendType="line"
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <YAxis
            domain={[
              (dataMin) => Math.round(dataMin),
              (dataMax) => Math.round(dataMax),
            ]}
            label={{
              value: 'ROI %',
              angle: -90,
              position: 'insideStart',
            }}
          />
          <XAxis
            dataKey="formattedTs"
            height={60}
            tick={<CustomizedAxisTick />}
          />
          <Tooltip />
          <Legend />
        </LineChart>
      );
    }
  };

  const renderCompositionChart = () => {
    const positions = sortBy(
      props?.portfolio?.positions,
      'currentWeight',
    ).reverse();
    const mainPositions = positions.filter((p) => p.currentWeight >= 2);
    const minorPositions = positions.filter((p) => p.currentWeight < 2);

    const data = [
      ...mainPositions,
      {
        companyName: 'Other',
        currentWeight: minorPositions.reduce(
          (acc, position) => acc + position.currentWeight,
          0,
        ),
      },
    ];

    return (
      <Treemap
        height={isMobile ? 100 : 300}
        width={isMobile ? 734 : 1100}
        data={data}
        dataKey="currentWeight"
        aspectRatio={4 / 3}
        stroke="#fff"
        fill="#8884d8"
        content={<CustomizedContent />}
      />
    );
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '10px',
        }}
      >
        <MetricsRangeSelectorComponent
          metricsRange={props?.metricsRange}
          setMetricsRange={props?.setMetricsRange}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Typography variant="h6">Valuation over time</Typography>
          <Box
            style={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-around',
            }}
          >
            {renderAverageBalancesLineChart()}
          </Box>
          <Typography variant="h6">ROI vs. indices</Typography>
          <Box
            style={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-around',
            }}
          >
            {renderReturnsLineChart()}
          </Box>
          <Typography variant="h6">Composition</Typography>
          <Box
            style={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-around',
            }}
          >
            {renderCompositionChart()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
