import {
  AddCard,
  CalendarMonth,
  Edit,
  Paid,
  Savings,
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
} from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import React from 'react';
import { editCash as callEditCash } from '../../../../http/PortfoliosApi';
import ContributionsListComponent from './ContributionListComponent';
import EditCashDialog from './dialogs/EditCashDialog';

export default function PortfolioManagementComponent({
  portfolio,
  loadPortfolio,
  contributions,
  reloadContributions,
  page,
  setPage,
}) {
  const [editCashDialogOpen, setEditCashDialogOpen] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState('An error happened!');

  const formatDate = (date) => {
    return date ? format(new Date(date), 'dd/MM/yy p') : '';
  };

  const amountFormatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  });

  const formatContributions = () =>
    `${amountFormatter.format(contributions?.sum ?? 0)} (${
      contributions?.count
    } contributions)`;

  const editCash = async ({ value }) => {
    await callEditCash(portfolio?.uuid, { cash: value });
    await loadPortfolio();
  };

  return (
    <Box>
      <EditCashDialog
        editCash={editCash}
        editCashDialogOpen={editCashDialogOpen}
        setEditCashDialogOpen={setEditCashDialogOpen}
        errMessage={errMessage}
        portfolio={portfolio}
        setErrMessage={setErrMessage}
        setSnackOpen={setSnackOpen}
      />
      <Grid container spacing={2} padding={1}>
        <Grid item xs={12} md={3}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddCard />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Contributions"
                secondary={formatContributions()}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Savings />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Cash available"
                secondary={amountFormatter.format(portfolio?.cash)}
              />
              <IconButton onClick={() => setEditCashDialogOpen(true)}>
                <Edit></Edit>
              </IconButton>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Paid />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Returns"
                secondary={amountFormatter.format(
                  portfolio?.state?.roicEUR || 0,
                )}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CalendarMonth />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Creation time"
                secondary={formatDate(portfolio?.created)}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
        </Grid>
        <ContributionsListComponent
          portfolio={portfolio}
          contributions={contributions}
          reloadContributions={reloadContributions}
          loadPortfolio={loadPortfolio}
          errMessage={errMessage}
          setErrMessage={setErrMessage}
          setSnackOpen={setSnackOpen}
          page={page}
          setPage={setPage}
          rowsPerPage={10}
        />
      </Grid>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert severity="error">{errMessage}</Alert>
      </Snackbar>
    </Box>
  );
}
