import { AppBar, Box, Typography, Toolbar } from '@mui/material';
import AuthIndicatorComponent from './auth/AuthIndicatorComponent';

export default function Header() {
  return (
    <Box style={{ display: 'flex', flex: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h6' noWrap component="div">
            Financial Advisor
          </Typography>
          <Box style={{ display: 'flex', flex: 1 }} />
          <AuthIndicatorComponent />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
