import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import LeftMenu from './components/LeftMenu';
import { getPortfolios } from './http/PortfoliosApi';

export default function App() {
  const [portfolios, setPortfolios] = useState([]);
  const [addPortfolioDialogOpen, setAddPortfolioDialogOpen] = useState(false);

  useEffect(() => {
    async function getPortfoliosList() {
      const { data } = await getPortfolios();
      setPortfolios(data);
    }

    getPortfoliosList();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="App">
        <Header />
        <Box style={{ display: 'flex' }}>
          <LeftMenu
            portfolios={portfolios}
            setAddPortfolioDialogOpen={setAddPortfolioDialogOpen}
          />
          <Box style={{ flexGrow: 1 }}>
            <Outlet
              context={[addPortfolioDialogOpen, setAddPortfolioDialogOpen]}
            />
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
