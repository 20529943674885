import axios from 'axios';
import { refreshSession } from './AuthApi';

const COMMON_HEADERS = { 'Content-Type': 'application/json' };
const ACCESS_TOKEN_KEY = 'fa-accessToken';
const ACCESS_TOKEN_EXPIRATION_DATE_KEY = 'fa-accessToken-expiration';
const REFRESH_TOKEN_KEY = 'fa-refreshToken';
const REFRESH_TOKEN_EXPIRATION_DATE_KEY = 'fa-refreshToken-expiration';

const refreshAuth = async () => {
  const refreshTokenExpiration = localStorage.getItem(REFRESH_TOKEN_EXPIRATION_DATE_KEY);

  if (refreshTokenExpiration && Date.now() >= refreshTokenExpiration) {
    return Promise.reject('Auth expired');
  }

  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  const { data } = await refreshSession({ data: { refreshToken } });
  localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken);
  localStorage.setItem(
    ACCESS_TOKEN_EXPIRATION_DATE_KEY,
    Date.now() + data.expiresIn * 1000,
  );

  return data.accessToken;
};

const getHeaders = async () => {
  let accessToken;
  const accessTokenExpiration = localStorage.getItem(
    ACCESS_TOKEN_EXPIRATION_DATE_KEY,
  );

  if (accessTokenExpiration && Date.now() >= accessTokenExpiration) {
    accessToken = await refreshAuth();
  } else {
    accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  return {
    ...COMMON_HEADERS,
    Authorization: `Bearer ${accessToken}`,
  };
};

class Api {
  static async get(url) {
    const headers = await getHeaders();
    const res = await axios.get(url, { headers });
    return res.data;
  }

  static async post(url, data) {
    const headers = await getHeaders();
    const res = await axios.post(url, data, { headers });
    return res.data;
  }

  static async put(url, data) {
    const headers = await getHeaders();
    const res = await axios.put(url, data, { headers });
    return res.data;
  }

  static async delete(url) {
    const headers = await getHeaders();
    const res = await axios.delete(url, { headers });
    return res.data;
  }
}

export default Api;
