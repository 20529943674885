import { Link } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import { Box, IconButton } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

export default function AuthIndicatorComponent() {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    return (
      <Box>
        {isAuthenticated
          ? (<IconButton size="large" onClick={() => navigate("/profile")} color="inherit">
              <AccountCircle />
            </IconButton>)
          : <Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>LOGIN</Link>
        }
      </Box>
    );
}
