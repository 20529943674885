import Api from './Api';

const PORTFOLIOS_URL = `${process.env.REACT_APP_BASE_URL}/v2/portfolios`;

export const addContribution = (uuid, data) =>
  Api.post(`${PORTFOLIOS_URL}/${uuid}/contributions`, data);

export const addPortfolio = (data) => Api.post(PORTFOLIOS_URL, data);

export const addPosition = (portfolioUuid, position) =>
  Api.post(`${PORTFOLIOS_URL}/${portfolioUuid}/positions`, position);

export const deleteContribution = (portfolioUuid, contributionUuid) =>
  Api.delete(`${PORTFOLIOS_URL}/${portfolioUuid}/contributions/${contributionUuid}`);

export const deletePortfolio = (uuid) =>
  Api.delete(`${PORTFOLIOS_URL}/${uuid}`);

export const deletePosition = (portfolioUuid, positionUuid) =>
  Api.delete(`${PORTFOLIOS_URL}/${portfolioUuid}/positions/${positionUuid}`);

export const editCash = (uuid, data) =>
  Api.put(`${PORTFOLIOS_URL}/${uuid}/cash`, data);

export const getPortfolio = (uuid) => Api.get(`${PORTFOLIOS_URL}/${uuid}`);

export const getContributions = (uuid, offset, limit) => (offset && limit)
  ? Api.get(`${PORTFOLIOS_URL}/${uuid}/contributions?offset=${offset}&limit=${limit}`)
  : Api.get(`${PORTFOLIOS_URL}/${uuid}/contributions`);

export const getPortfolioAverageBalances = (uuid, range) =>
  Api.get(`${PORTFOLIOS_URL}/${uuid}/metrics/average-balances?range=${range}`);

export const getPortfolioReturns = (uuid, range) =>
  Api.get(`${PORTFOLIOS_URL}/${uuid}/metrics/performance/returns?range=${range}`);

export const getPortfolios = () => Api.get(PORTFOLIOS_URL);

export const putPosition = (portfolioUuid, position) =>
  Api.put(`${PORTFOLIOS_URL}/${portfolioUuid}/positions`, position);
