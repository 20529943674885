import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import useAuth from './hooks/useAuth';

export default function AuthComponent() {
  const { register, handleSubmit } = useForm();
  const { isAuthLoading, isAuthError, login } = useAuth();

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit((credentials) => login(credentials))}
          >
            <TextField
              {...register('email')}
              autoComplete="email"
              autoFocus
              fullWidth
              id="email"
              margin="normal"
              name="email"
              error={isAuthError}
              required
            />
            <TextField
              {...register('password')}
              autoComplete="current-password"
              fullWidth
              id="password"
              margin="normal"
              name="password"
              placeholder="Password"
              error={isAuthError}
              required
              type="password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
          {isAuthLoading && (
            <Box style={{ marginTop: '60px' }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
