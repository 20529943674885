import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Alert,
  Box,
  Chip,
  Grid,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { get, round } from 'lodash';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useOutletContext, useParams } from 'react-router-dom';

import {
  deletePosition,
  getContributions,
  getPortfolio,
  getPortfolioAverageBalances,
  getPortfolioReturns,
  putPosition,
} from '../../http/PortfoliosApi';
import AddPortfolioDialog from './dialogs/AddPortfolioDialog';

import PortfolioAnalyticsComponent from './tabs/analytics/PortfolioAnalyticsComponent';
import PortfolioManagementComponent from './tabs/management/PortfolioManagementComponent';
import PortfolioStatusComponent from './tabs/status/PortfolioStatusComponent';

export default function PortfolioComponent() {
  const [addPortfolioDialogOpen, setAddPortfolioDialogOpen] =
    useOutletContext();
  const { uuid } = useParams();
  const [portfolio, setPortfolio] = useState({});
  const [contributions, setContributions] = useState({});
  const [metricsRange, setMetricsRange] = useState('sixMonths');
  const [portfolioMetrics, setPortfolioMetrics] = useState({});
  const [positions, setPositions] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [errMessage, setErrMessage] = useState('An error happened!');

  const [meta, setMeta] = useState({
    sumWeights: 100,
    isValid: true,
    totalValueEUR: 0,
  });
  const [value, setValue] = useState(0);
  const [portfolios, setPortfolios] = useState([]);
  const [contributionsPage, setContributionsPage] = useState(0);

  async function loadPortfolio() {
    const { data } = await getPortfolio(uuid);
    const { data: contributionsData } = await getContributions(uuid);
    setPortfolio(data);
    setContributions(contributionsData);
    setPositions(
      (data &&
        data.positions &&
        data.positions.map((p) => ({ ...p, id: p.uuid }))) ||
        [],
    );

    setMeta({
      sumWeights: data.state.sumWeights,
      isValid: data.state.isValid,
      totalValueEUR: data.state.totalValueEUR,
    });
  }

  async function reloadContributions(offset, limit) {
    const { data: contributionsData } = await getContributions(
      uuid,
      offset,
      limit,
    );
    setContributions(contributionsData);
  }

  async function loadPortfolioMetrics() {
    const { data } = await getPortfolioAverageBalances(uuid, metricsRange);
    const { data: returns } = await getPortfolioReturns(uuid, metricsRange);
    setPortfolioMetrics({
      ...portfolioMetrics,
      averageBalances: data,
      returns,
    });
  }
  useEffect(() => {
    if (uuid) {
      loadPortfolio();
      loadPortfolioMetrics();
    }
  }, [uuid, metricsRange]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateRow = async (newValue, oldValue) => {
    try {
      const { symbol, targetWeight, shares, blocked } = newValue;
      await putPosition(portfolio.uuid, {
        symbol,
        targetWeight,
        shares,
        blocked,
      });
      await loadPortfolio();
      return newValue;
    } catch (err) {
      setErrMessage(get(err, 'response.data.message', errMessage));
      setSnackOpen(true);
      return oldValue;
    }
  };

  const deleteRow = async (positionUuid) => {
    await deletePosition(portfolio.uuid, positionUuid);
    setPositions(positions.filter((r) => r.id !== positionUuid));
    await loadPortfolio();
  };

  const blockPosition = async (positionUuid) => {
    const position = positions.find((p) => p.uuid === positionUuid);
    await putPosition(portfolio.uuid, {
      symbol: position.symbol,
      targetWeight: position.targetWeight,
      shares: position.shares,
      blocked: true,
    });
    await loadPortfolio();
  };

  const unBlockPosition = async (positionUuid) => {
    const position = positions.find((p) => p.uuid === positionUuid);
    await putPosition(portfolio.uuid, {
      symbol: position.symbol,
      targetWeight: position.targetWeight,
      shares: position.shares,
      blocked: false,
    });
    await loadPortfolio();
  };

  const getPortfolioStateTimestamp = () => {
    const timestamp = portfolio?.state?.timestamp;
    return timestamp ? format(new Date(timestamp), 'dd/MM p') : '';
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <Box style={{ display: 'flex', alignSelf: 'center' }}>
      <Grid container padding={4}>
        <Box
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            flexGrow: 1,
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Box
            style={{
              flex: 1,
              textAlign: 'left',
              alignSelf: 'flex-start',
              marginLeft: '12px',
            }}
          >
            <Typography variant="subtitle2">
              Last update: {getPortfolioStateTimestamp()}
            </Typography>
          </Box>
          {meta.isValid ? (
            <Chip
              label={`${meta.sumWeights}% assigned`}
              icon={<CheckCircleIcon />}
              color="primary"
            />
          ) : (
            <Chip
              label={`${meta.sumWeights}% assigned`}
              icon={<ClearIcon />}
              color="error"
            />
          )}
          <Chip
            variant="outlined"
            icon={<AttachMoneyIcon />}
            color="success"
            label={`Total value: ${round(meta.totalValueEUR, 2)} EUR`}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Portfolio Status" />
              <Tab label="Portfolio Analytics" />
              <Tab label="Portfolio Management" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PortfolioStatusComponent
              uuid={uuid}
              loadPortfolio={loadPortfolio}
              setAddPortfolioDialogOpen={setAddPortfolioDialogOpen}
              portfolios={portfolios}
              setPortfolios={setPortfolios}
              deleteRow={deleteRow}
              blockPosition={blockPosition}
              unBlockPosition={unBlockPosition}
              portfolio={portfolio}
              positions={positions}
              updateRow={updateRow}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PortfolioAnalyticsComponent
              setMetricsRange={setMetricsRange}
              portfolioMetrics={portfolioMetrics}
              contributions={contributions}
              portfolio={portfolio}
              metricsRange={metricsRange}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PortfolioManagementComponent
              contributions={contributions}
              reloadContributions={reloadContributions}
              portfolio={portfolio}
              loadPortfolio={loadPortfolio}
              page={contributionsPage}
              setPage={setContributionsPage}
            />
          </TabPanel>
        </Box>
      </Grid>
      <AddPortfolioDialog
        setPortfolios={setPortfolios}
        addPortfolioDialogOpen={addPortfolioDialogOpen}
        setAddPortfolioDialogOpen={setAddPortfolioDialogOpen}
        portfolios={portfolios}
        errMessage={errMessage}
        setErrMessage={setErrMessage}
        setSnackOpen={setSnackOpen}
      />
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert severity="error">{errMessage}</Alert>
      </Snackbar>
    </Box>
  );
}
