import {
  Add,
  BlockOutlined,
  Check,
  Delete,
  DeleteOutline,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format, isBefore, subMonths } from 'date-fns';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import DeletePortfolioDialog from '../../dialogs/DeletePortfolioDialog';
import AddPositionDialog from './dialogs/AddPositionDialog';
import DeletePositionDialog from '../..//dialogs/DeletePositionDialog';

export default function PortfolioStatusComponent(props) {
  const [pageSize, setPageSize] = useState(50);
  const [addPositionDialogOpen, setAddPositionDialogOpen] = useState(false);
  const [deletePortfolioDialogOpen, setDeletePortfolioDialogOpen] =
    useState(false);
  const [deletePositionDialogOpen, setDeletePositionDialogOpen] =
    useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [errMessage, setErrMessage] = useState('An error happened!');
  const [activePositionId, setActivePositionId] = useState();

  const buildColumns = () => {
    return [
      {
        field: 'symbol',
        headerName: 'Symbol',
        flex: 1,
      },
      {
        field: 'companyName',
        headerName: 'Company',
        flex: 2.5,
      },
      {
        field: 'evr',
        headerName: 'EV/REV',
        type: 'number',
        flex: 2 / 3,
        valueGetter: (_, row) => {
          return row.companyState.enterpriseToRevenue;
        },
        renderCell: (data) => {
          const value = data.row.companyState.enterpriseToRevenue;
          const fontWeight = value <= 0 ? 'bold' : 'medium';
          return (
            <Typography variant="body" fontWeight={{ fontWeight }}>
              {value.toFixed(2)}
            </Typography>
          );
        },
      },
      {
        field: 'eve',
        headerName: 'EV/EBITDA',
        type: 'number',
        flex: 2 / 3,
        valueGetter: (_, row) => {
          return row.companyState.enterpriseToEbitda;
        },
        renderCell: (data) => {
          const value = data.row.companyState.enterpriseToEbitda;
          const fontWeight = value <= 0 ? 'bold' : 'medium';
          return (
            <Typography variant="body" fontWeight={{ fontWeight }}>
              {value.toFixed(2)}
            </Typography>
          );
        },
      },
      {
        field: 'peg',
        headerName: 'PEG',
        type: 'number',
        flex: 2 / 3,
        valueGetter: (_, row) => row.companyState?.peg,
      },
      {
        field: 'short',
        headerName: 'Short %',
        type: 'number',
        flex: 2 / 3,
        valueGetter: (_, row) => row?.companyState?.shortPercentOfFloat,
        renderCell: (data) => {
          const value = data?.row?.companyState?.shortPercentOfFloat;
          const fontWeight = value >= 0.025 ? 'bold' : 'medium';
          return (
            <Typography variant="body" fontWeight={{ fontWeight }}>
              {Number(value * 100).toFixed(2)}%
            </Typography>
          );
        },
      },
      {
        field: 'value',
        headerName: 'Value',
        type: 'number',
        flex: 1,
        renderCell: (data) => (
          <Typography variant="body">{data?.row?.value?.toFixed(2)}</Typography>
        ),
      },
      {
        field: 'currentWeight',
        headerName: 'Current',
        type: 'number',
        flex: 2 / 3,
      },
      {
        field: 'targetWeight',
        headerName: 'Target',
        type: 'number',
        editable: true,
        flex: 2 / 3,
      },
      {
        field: 'deltaWeight',
        headerName: 'Delta weight',
        type: 'number',
        flex: 1,
        valueGetter: (_, row) => {
          return row?.deltaWeight;
        },
        renderCell: ({ row }) => {
          const color = row.deltaWeight < 0 ? 'red' : 'green';
          const fontWeight =
            Math.abs(row.deltaWeight) > 0.1 ? 'bold' : 'medium';
          return (
            <Typography
              variant="body"
              color={{ color }}
              fontWeight={{ fontWeight }}
            >
              {`${row.deltaWeight.toFixed(3)}${row.blocked ? '✋🏼' : ''}`}
            </Typography>
          );
        },
      },
      {
        field: 'deltaShares',
        headerName: 'Delta shares',
        type: 'number',
        flex: 1,
        valueGetter: (_, row) => {
          return row?.deltaShares * -1;
        },
        renderCell: ({ row }) => {
          const value = row?.deltaShares * -1;
          const color = value < 0 ? 'red' : 'green';
          return (
            <Typography variant="body" color={{ color }}>
              {value.toFixed(3)}
            </Typography>
          );
        },
      },
      {
        field: 'shares',
        headerName: 'Shares',
        type: 'number',
        editable: true,
        flex: 2 / 3,
        renderCell: ({ row, value }) => {
          const isRecent = isBefore(
            subMonths(new Date(), 1),
            new Date(row.sharesUpdatedAt),
          );
          const color = isRecent ? 'red' : 'green';
          return (
            <Typography variant="body" color={{ color }}>
              {value}
            </Typography>
          );
        },
      },
      {
        field: 'sharesUpdatedAt',
        headerName: 'Last change',
        type: 'date',
        flex: 2 / 3,
        valueGetter: (_, row) =>
          row.sharesUpdatedAt ? new Date(row.sharesUpdatedAt) : null,
        renderCell: ({ row }) => {
          const isRecent = isBefore(
            subMonths(new Date(), 1),
            new Date(row.sharesUpdatedAt),
          );
          const color = isRecent ? 'red' : 'green';
          return (
            <Typography variant="body" color={{ color }}>
              {format(new Date(row.sharesUpdatedAt), 'dd/MM/yyyy')}
            </Typography>
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: ({ id, row }) => (
          <Box>
            <IconButton
              onClick={() => {
                setActivePositionId(id);
                setDeletePositionDialogOpen(true);
              }}
              size="small"
            >
              <DeleteOutline />
            </IconButton>
            {row.blocked ? (
              <IconButton
                onClick={() => props?.unBlockPosition(id)}
                size="small"
              >
                <Check />
              </IconButton>
            ) : (
              <IconButton onClick={() => props?.blockPosition(id)} size="small">
                <BlockOutlined />
              </IconButton>
            )}
          </Box>
        ),
      },
    ];
  };

  return (
    <Box>
      <Grid item xs={12} padding={1}>
        <DataGrid
          autoHeight
          wi
          rows={props?.positions}
          columns={buildColumns()}
          columnVisibilityModel={{
            companyName: !isMobile,
            evr: !isMobile,
            eve: !isMobile,
            peg: !isMobile,
            short: !isMobile,
            value: !isMobile,
            sharesUpdatedAt: !isMobile,
            actions: !isMobile,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'deltaWeight', sort: 'asc' }],
            },
          }}
          density="compact"
          experimentalFeatures={{ newEditingApi: true }}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          rowsPerPageOptions={[5, 10, 20, 50]}
          processRowUpdate={props?.updateRow}
        />
      </Grid>
      <Box
        padding={1}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          onClick={() => setDeletePortfolioDialogOpen(true)}
          variant="outlined"
          endIcon={<Delete />}
          color="error"
        >
          Delete portfolio
        </Button>
        <Button
          onClick={() => setAddPositionDialogOpen(true)}
          variant="outlined"
          endIcon={<Add />}
        >
          Add position
        </Button>
      </Box>
      <AddPositionDialog
        activePortfolioUuid={props.uuid}
        loadPortfolio={props.loadPortfolio}
        addPositionDialogOpen={addPositionDialogOpen}
        setAddPositionDialogOpen={setAddPositionDialogOpen}
        errMessage={errMessage}
        setErrMessage={setErrMessage}
        setSnackOpen={setSnackOpen}
      />
      <DeletePortfolioDialog
        activePortfolioUuid={props.uuid}
        setPortfolios={props.setPortfolios}
        deletePortfolioDialogOpen={deletePortfolioDialogOpen}
        setDeletePortfolioDialogOpen={setDeletePortfolioDialogOpen}
        portfolios={props.portfolios}
        errMessage={errMessage}
        setErrMessage={setErrMessage}
        setSnackOpen={setSnackOpen}
      />
      <DeletePositionDialog
        deletePositionDialogOpen={deletePositionDialogOpen}
        setDeletePositionDialogOpen={setDeletePositionDialogOpen}
        deleteRow={props?.deleteRow}
        positionId={activePositionId}
      />
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert severity="error">{errMessage}</Alert>
      </Snackbar>
    </Box>
  );
}
