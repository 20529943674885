import { Button, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import useAuth from "./hooks/useAuth";

const KeyItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const ValueItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export default function ProfileComponent() {
  const { userData, logout } = useAuth();

  return (
    <Box sx={{ flexGrow: 1 }} margin={5}>
      <Grid container spacing={2} item xs={6}>
        <Grid item xs={6} sm={3}>
            <KeyItem>Username</KeyItem>
        </Grid>
        <Grid item xs={6} sm={9}>
            <ValueItem>{userData.fullName}</ValueItem>
        </Grid>
        <Grid item xs={6} sm={3}>
            <KeyItem>Email</KeyItem>
        </Grid>
        <Grid item xs={6} sm={9}>
            <ValueItem>{userData.email}</ValueItem>
        </Grid>
        <Grid item xs={6} sm={3}>
            <KeyItem>Role</KeyItem>
        </Grid>
        <Grid item xs={6} sm={9}>
            <ValueItem>{userData.role}</ValueItem>
        </Grid>
        <Grid item xs={6} sm={3}></Grid>
        <Grid item xs={6} sm={9} textAlign="left">
            <Button variant="outlined" onClick={() => logout()}>Logout</Button>
        </Grid>
      </Grid>
    </Box>
  );
}
