import { Add as AddIcon, DeleteOutline } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { get, isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { deleteCompany, getCompanies } from '../../http/CompaniesApi';
import AddCompanyDialog from './dialogs/AddCompanyDialog';

export default function CompaniesComponent() {
  const [pageSize, setPageSize] = useState(50);
  const [companies, setCompanies] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [errMessage, setErrMessage] = useState('An error happened!');
  const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);

  useEffect(() => {
    async function getData() {
      const { data } = await getCompanies();
      setCompanies((data && data.map((c) => ({ ...c, id: c.uuid }))) || []);
    }

    getData();
  }, []);

  const renderValue = (metric, value) => {
    const isValid = isNumber(value) && value > 0 && isNumber(metric);
    const isUp = isValid && value > metric * 1.2;
    const isDoubleUp = isValid && value > metric * 1.5;
    const isDown = isValid && value < metric * 0.85;
    const isDoubleDown = isValid && value < metric * 0.6;
    return isValid ? (
      <Typography variant="body">
        {value.toFixed(2)} ({metric?.toFixed(2) ?? '??'})
        {isDoubleUp ? '🥦🥦' : isUp ? '🥦' : ''}
        {isDoubleDown ? '🍅🍅' : isDown ? '🍅' : ''}
      </Typography>
    ) : (
      <Box></Box>
    );
  };

  const buildGrid = () => {
    return [
      {
        field: 'timestamp',
        headerName: 'Last update',
        flex: 1,
        valueGetter: (_, row) =>
          format(new Date(row?.state?.timestamp), 'dd/MM p'),
      },
      {
        field: 'symbol',
        headerName: 'Symbol',
        flex: 1,
        renderCell: ({ formattedValue }) => (
          <Typography variant="body" fontWeight="bold">
            {formattedValue}
          </Typography>
        ),
      },
      {
        field: 'name',
        headerName: 'Company',
        flex: 2.5,
      },
      {
        field: 'price',
        type: 'number',
        valueGetter: (_, row) => row.state.price,
        headerName: 'Price',
        flex: 1,
      },
      {
        field: 'currency',
        valueGetter: (_, row) => row.state.currency,
        headerName: 'Currency',
        flex: 1,
      },
      {
        field: 'EV/REV',
        headerName: 'EV/REV',
        type: 'number',
        flex: 1,
        valueGetter: (_, row) => row?.state?.enterpriseToRevenue,
        renderCell: ({ row }) => {
          const value = Number(row?.state?.enterpriseToRevenue);
          const company = companies.find((c) => c.uuid === row.uuid);
          return renderValue(company.metrics?.avgEnterpriseToRevenue, value);
        },
      },
      {
        field: 'EV/EBITDA',
        headerName: 'EV/EBITDA',
        type: 'number',
        flex: 1,
        valueGetter: (_, row) => row?.state?.enterpriseToEbitda,
        renderCell: ({ formattedValue, row }) => {
          const value = Number(formattedValue);
          const company = companies.find((c) => c.uuid === row.uuid);
          return renderValue(company.metrics?.avgEnterpriseToEbitda, value);
        },
      },
      {
        field: 'peg',
        headerName: 'PEG',
        type: 'number',
        flex: 1,
        valueGetter: (_, row) => row?.state?.peg,
        renderCell: ({ formattedValue, row }) => {
          const value = Number(formattedValue);
          const company = companies.find((c) => c.uuid === row.uuid);
          return renderValue(company.metrics?.avgPeg, value);
        },
      },
      {
        field: 'Short %',
        headerName: 'Short %',
        type: 'number',
        flex: 1,
        valueGetter: (_, row) => row?.state?.shortPercentOfFloat,
        renderCell: ({ formattedValue }) => {
          const value = Number(formattedValue);
          const fontWeight = value >= 0.025 ? 'bold' : 'medium';
          return (
            <Typography variant="body" fontWeight={{ fontWeight }}>
              {Number(value * 100).toFixed(2)}%
            </Typography>
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: (data) => {
          return (
            <IconButton onClick={() => deleteRow(data.id)} size="small">
              <DeleteOutline />
            </IconButton>
          );
        },
      },
    ];
  };

  const deleteRow = async (companyUuid) => {
    try {
      await deleteCompany(companyUuid);
      setCompanies(companies.filter((r) => r.id !== companyUuid));
    } catch (err) {
      setErrMessage(get(err, 'response.data.message', errMessage));
      setSnackOpen(true);
    }
  };

  return (
    <Box padding={4}>
      <Grid padding={1}>
        <DataGrid
          autoHeight
          wi
          rows={companies}
          columns={buildGrid()}
          columnVisibilityModel={{
            name: !isMobile,
            currency: !isMobile,
            actions: !isMobile,
          }}
          density="compact"
          experimentalFeatures={{ newEditingApi: true }}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          rowsPerPageOptions={[5, 10, 20, 50]}
        />
      </Grid>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert severity="error">{errMessage}</Alert>
      </Snackbar>
      <Box padding={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => setAddCompanyDialogOpen(true)}
          variant="outlined"
          endIcon={<AddIcon />}
        >
          Add company
        </Button>
      </Box>
      <AddCompanyDialog
        setCompanies={setCompanies}
        companies={companies}
        addCompanyDialogOpen={addCompanyDialogOpen}
        setAddCompanyDialogOpen={setAddCompanyDialogOpen}
        setErrMessage={setErrMessage}
        errMessage={errMessage}
        setSnackOpen={setSnackOpen}
      />
    </Box>
  );
}
