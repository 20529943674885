import React from 'react';
import ReactDOM from 'react-dom/client';
import Portfolio from './routes/portfolio';
import Companies from './routes/companies';
import PrivateRoute from './routes/shared/PrivateRoute';
import App from './App';
import './index.css';

import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AuthComponent from './components/auth/AuthComponent';
import Profile from './routes/profile';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/login" element={<AuthComponent />} />
          <Route path="/portfolios/:uuid" element={<PrivateRoute><Portfolio /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/companies" element={<PrivateRoute><Companies /></PrivateRoute>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
