import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login as requestLogin } from '../../../http/AuthApi';

const ACCESS_TOKEN_KEY = 'fa-accessToken';
const ACCESS_TOKEN_EXPIRATION_DATE_KEY = 'fa-accessToken-expiration';
const REFRESH_TOKEN_KEY = 'fa-refreshToken';
const REFRESH_TOKEN_EXPIRATION_DATE_KEY = 'fa-refreshToken-expiration';
const USER_KEY = 'fa-user';

export default function useAuth() {
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [accessToken, setAccessToken] = useState('');
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const loadAuth = async () => {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      const userStr = localStorage.getItem(USER_KEY);
      const user = JSON.parse(userStr);
      setAuthData({ accessToken, refreshToken, user });

      return setIsAuthenticated(!!accessToken);
    };
    loadAuth();
  }, []);

  const setAuthData = (authData) => {
    setAccessToken(authData.accessToken);
    setUserData(!isEmpty(authData.user) ? authData.user : {});
  };

  const persistAuthData = (authData) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, authData.accessToken);
    localStorage.setItem(
      ACCESS_TOKEN_EXPIRATION_DATE_KEY,
      authData.accessTokenExpiration,
    );
    localStorage.setItem(REFRESH_TOKEN_KEY, authData.refreshToken);
    localStorage.setItem(
      REFRESH_TOKEN_EXPIRATION_DATE_KEY,
      authData.refreshTokenExpiration,
    );
    localStorage.setItem(USER_KEY, JSON.stringify(authData.user));
  };

  const cleanAuthData = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_DATE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_EXPIRATION_DATE_KEY);
    localStorage.removeItem(USER_KEY);
  };

  const login = async (credentials) => {
    try {
      setIsAuthLoading(true);
      setIsAuthError(false);
      const { data } = await requestLogin({ data: credentials });

      const authData = {
        ...data,
        accessTokenExpiration: Date.now() + data.expiresIn * 1000,
        refreshTokenExpiration: Date.now() + data.refreshTokenExpiresIn * 1000,
      };

      persistAuthData(authData);
      setAuthData(authData);
      setIsAuthLoading(false);
      setIsAuthenticated(true);

      navigate('/');
      window.location.reload(false);
    } catch (err) {
      setIsAuthLoading(false);
      setIsAuthError(true);
      setIsAuthenticated(false);
    }
  };

  const logout = () => {
    cleanAuthData();
    setIsAuthenticated(false);
    navigate('/');
    window.location.reload(false);
  };

  return {
    accessToken,
    isAuthLoading,
    isAuthError,
    isAuthenticated,
    login,
    logout,
    userData,
  };
}
