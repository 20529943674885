import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { get } from 'lodash';
import React from 'react';
import { addCompany } from '../../../http/CompaniesApi';

export default function AddCompanyDialog({
  setCompanies,
  companies,
  addCompanyDialogOpen,
  setAddCompanyDialogOpen,
  setErrMessage,
  errMessage,
  setSnackOpen,
}) {
  const initialAddCompanyFormData = Object.freeze({ symbol: '', name: '' });
  const [addCompanyFormData, setAddCompanyFormData] = React.useState(
    initialAddCompanyFormData,
  );

  const handleAddCompany = (event) => {
    event.preventDefault();

    setAddCompanyFormData({
      ...addCompanyFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddCompanySubmit = async (event) => {
    event.preventDefault();

    try {
      const { name, symbol } = addCompanyFormData;
      const { data } = await addCompany({ name, symbol });
      setCompanies([...companies, { ...data, id: data.uuid }]);
      setAddCompanyDialogOpen(false);
    } catch (err) {
      setErrMessage(get(err, 'response.data.message', errMessage));
      setSnackOpen(true);
    }
  };

  return (
    <Dialog
      fullWidth
      open={addCompanyDialogOpen}
      onClose={() => setAddCompanyDialogOpen(false)}
    >
      <DialogTitle>Add company</DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddCompanySubmit}>
          <TextField
            name="symbol"
            style={{ margin: 10 }}
            label="Symbol"
            onChange={handleAddCompany}
          />
          <TextField
            name="name"
            style={{ margin: 10 }}
            label="Name"
            onChange={handleAddCompany}
          />
          <Button type="submit">Add company</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
