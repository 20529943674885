import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { get } from 'lodash';
import React from 'react';
import { addPortfolio } from '../../../http/PortfoliosApi';

export default function AddPortfolioDialog({
  setPortfolios,
  addPortfolioDialogOpen,
  setAddPortfolioDialogOpen,
  portfolios,
  errMessage,
  setErrMessage,
  setSnackOpen,
}) {
  const initialAddPortfolioFormData = Object.freeze({ name: '' });
  const [addPortfolioFormData, setAddPortfolioFormData] = React.useState(
    initialAddPortfolioFormData,
  );

  const handleAddPortfolio = (event) => {
    event.preventDefault();

    setAddPortfolioFormData({
      ...addPortfolioFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddPortfolioSubmit = async (event) => {
    event.preventDefault();

    try {
      const { name } = addPortfolioFormData;
      const { data } = await addPortfolio({ name });
      setPortfolios([...portfolios, data]);
      setAddPortfolioDialogOpen(false);
      window.location.reload(false);
    } catch (err) {
      setErrMessage(get(err, 'response.data.message', errMessage));
      setSnackOpen(true);
    }
  };

  return (
    <Dialog
      fullWidth
      open={addPortfolioDialogOpen}
      onClose={() => setAddPortfolioDialogOpen(false)}
    >
      <DialogTitle>Add portfolio</DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddPortfolioSubmit}>
          <TextField
            name="name"
            style={{ margin: 10 }}
            label="Name"
            onChange={handleAddPortfolio}
          />
          <Button type="submit">Add portfolio</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
