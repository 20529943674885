import { AddCard, DeleteOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import {
  addContribution as callAddContribution,
  deleteContribution as callDeleteContribution,
} from '../../../../http/PortfoliosApi';
import AddContributionDialog from './dialogs/AddContributionDialog';

export default function ContributionsListComponent({
  portfolio,
  contributions,
  reloadContributions,
  loadPortfolio,
  errMessage,
  setErrMessage,
  setSnackOpen,
  page,
  setPage,
}) {
  const [addContributionDialogOpen, setAddContributionDialogOpen] =
    React.useState(false);

  const handleChangePage = (event, newPage) => {
    reloadContributions(Number(newPage * 10), 10);
    setPage(newPage);
  };

  const formatDate = (date) => {
    return date ? format(new Date(date), 'dd/MM/yy p') : '';
  };

  const amountFormatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  });

  const addContribution = async (data) => {
    await callAddContribution(portfolio?.uuid, data);
    await loadPortfolio();
  };

  const deleteContribution = async (contribution) => {
    await callDeleteContribution(portfolio?.uuid, contribution?.uuid);
    await loadPortfolio();
  };

  const hasContributions = () => contributions?.items?.length;

  return (
    <Grid item xs={12} md={4}>
      <Typography variant="overline">Contributions</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size="small">Time</TableCell>
              <TableCell size="small">Amount</TableCell>
              <TableCell size="small"></TableCell>
            </TableRow>
          </TableHead>
          {hasContributions() ? (
            <TableBody>
              {contributions?.items?.map((contribution) => (
                <TableRow key={contribution.timestamp}>
                  <TableCell size="small">
                    {formatDate(contribution.timestamp)}
                  </TableCell>
                  <TableCell size="small">
                    {amountFormatter.format(contribution.amountEUR)}
                  </TableCell>
                  <TableCell size="small">
                    <IconButton
                      onClick={() => deleteContribution(contribution)}
                      size="small"
                      data={contribution.uuid}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>No contributions yet.</TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={contributions.count}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box style={{ margin: 10, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{ display: 'flex' }}
          variant="contained"
          endIcon={<AddCard />}
          onClick={() => setAddContributionDialogOpen(true)}
        >
          Add contribution
        </Button>
      </Box>
      <AddContributionDialog
        addContribution={addContribution}
        addContributionDialogOpen={addContributionDialogOpen}
        setAddContributionDialogOpen={setAddContributionDialogOpen}
        errMessage={errMessage}
        portfolio={portfolio}
        setErrMessage={setErrMessage}
        setSnackOpen={setSnackOpen}
      />
    </Grid>
  );
}
