import React from "react";
import { get } from 'lodash';
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { deletePortfolio } from "../../../http/PortfoliosApi";

export default function DeletePortfolioDialog({
  activePortfolioUuid,
  setPortfolios,
  deletePortfolioDialogOpen,
  setDeletePortfolioDialogOpen,
  portfolios,
  errMessage,
  setErrMessage,
  setSnackOpen,
}) {
  const handleDeletePortfolioSubmit = async (event) => {
    event.preventDefault();

    try {
      await deletePortfolio(activePortfolioUuid);
      setPortfolios(portfolios.filter(p => p !== activePortfolioUuid));
      setDeletePortfolioDialogOpen(false)
      window.location.reload(false);
    } catch(err) {
      setErrMessage(get(err, 'response.data.message', errMessage));
      setSnackOpen(true);
    }
  }

  return (
    <Dialog fullWidth open={deletePortfolioDialogOpen} onClose={() => setDeletePortfolioDialogOpen(false)}>
        <DialogTitle>Delete portfolio</DialogTitle>
        <DialogContent>
          <form onSubmit={handleDeletePortfolioSubmit}>
            <Button type="submit" variant="outlined" color="error">Delete portfolio</Button>
          </form>
        </DialogContent>
      </Dialog>
  )
}
