import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { get, sortBy } from 'lodash';
import React from 'react';
import { getCompanies } from '../../../../../http/CompaniesApi';
import { addPosition } from '../../../../../http/PortfoliosApi';

export default function AddPositionDialog({
  activePortfolioUuid,
  loadPortfolio,
  addPositionDialogOpen,
  setAddPositionDialogOpen,
  errMessage,
  setErrMessage,
  setSnackOpen,
}) {
  const initialAddPositionFormData = Object.freeze({
    companyUuid: '',
    targetWeight: 0,
    shares: 0,
  });

  const [companies, setCompanies] = React.useState([]);
  const [addPositionFormData, setAddPositionFormData] = React.useState(
    initialAddPositionFormData,
  );

  React.useEffect(() => {
    async function getData() {
      const { data } = await getCompanies();
      const companiesList =
        data &&
        sortBy(
          data.map((c) => ({ ...c, id: c.uuid })),
          'name',
        );
      setCompanies(companiesList || []);
    }

    getData();
  }, []);

  const handleAddPosition = (event) => {
    event.preventDefault();

    setAddPositionFormData({
      ...addPositionFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddPositionSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        symbol: get(
          companies.find((c) => c.uuid === addPositionFormData.companyUuid),
          'symbol',
        ),
        targetWeight: Number(addPositionFormData.targetWeight),
        shares: Number(addPositionFormData.shares),
        blocked: false,
      };

      await addPosition(activePortfolioUuid, data);
      await loadPortfolio();
      setAddPositionDialogOpen(false);
    } catch (err) {
      setErrMessage(get(err, 'response.data.message', errMessage));
      setSnackOpen(true);
    }
  };

  return (
    <Dialog
      fullWidth
      open={addPositionDialogOpen}
      onClose={() => setAddPositionDialogOpen(false)}
    >
      <DialogTitle>Add position</DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddPositionSubmit}>
          <Grid>
            <Select
              name="companyUuid"
              style={{ margin: 10 }}
              fullWidth
              labelId="company-select-label"
              id="company-select"
              value={addPositionFormData.companyUuid}
              onChange={handleAddPosition}
            >
              {companies.map((c) => (
                <MenuItem key={c.uuid} value={c.uuid}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              name="targetWeight"
              style={{ margin: 10 }}
              label="Target weight"
              onChange={handleAddPosition}
            />
            <TextField
              name="shares"
              style={{ margin: 10 }}
              label="Shares"
              onChange={handleAddPosition}
            />
            <Button type="submit">Add position</Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
