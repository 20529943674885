import { AddCard } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { get } from 'lodash';
import React from 'react';

export default function AddContributionDialog({
  addContribution,
  addContributionDialogOpen,
  setAddContributionDialogOpen,
  errMessage,
  setErrMessage,
  setSnackOpen,
}) {
  const initialAddContributionFormData = Object.freeze({
    timestamp: new Date(),
    amountEUR: 1,
  });
  const [addContributionFormData, setAddContributionFormData] = React.useState(
    initialAddContributionFormData,
  );

  const handleDateChange = (newValue) => {
    setAddContributionFormData({
      ...addContributionFormData,
      timestamp: newValue,
    });
  };

  const handleAddContribution = (event) => {
    event.preventDefault();

    setAddContributionFormData({
      ...addContributionFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddContributionSubmit = async (event) => {
    event.preventDefault();

    try {
      const { timestamp, amountEUR } = addContributionFormData;
      await addContribution({
        timestamp: timestamp.toISOString(),
        amountEUR: Number(amountEUR),
      });
      setAddContributionDialogOpen(false);
    } catch (err) {
      setErrMessage(get(err, 'response.data.message', errMessage));
      setSnackOpen(true);
    }
  };

  return (
    <Dialog
      open={addContributionDialogOpen}
      onClose={() => setAddContributionDialogOpen(false)}
    >
      <DialogTitle>Add contribution</DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddContributionSubmit}>
          <Box style={{ margin: 10 }}>
            <MobileDatePicker
              name="timestamp"
              label="Date"
              inputFormat="dd/MM/yyyy"
              value={addContributionFormData.timestamp}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <TextField
            type="number"
            name="amountEUR"
            style={{ margin: 10 }}
            label="Amount"
            onChange={handleAddContribution}
          />
          <Divider />
          <Box style={{ margin: 10 }}>
            <Button variant="contained" endIcon={<AddCard />} type="submit">
              Add contribution
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
