import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default function DeletePositionDialog({
  deletePositionDialogOpen,
  setDeletePositionDialogOpen,
  deleteRow,
  positionId,
}) {
  const handleDeletePositionSubmit = async (event) => {
    event.preventDefault();

    await deleteRow(positionId);
  };
  return (
    <Dialog
      fullWidth
      open={deletePositionDialogOpen}
      onClose={() => setDeletePositionDialogOpen(false)}
    >
      <DialogTitle>Delete Position</DialogTitle>
      <DialogContent>
        <form onSubmit={handleDeletePositionSubmit}>
          <DialogContentText>
            Please confirm the position deletion
          </DialogContentText>
          <DialogActions>
            <Button type="submit" variant="outlined" color="error">
              Delete Position
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
